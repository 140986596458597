import Actions from './Actions'
import Dispatcher from '../dispatchers/Dispatcher'
import { gridNumbers } from '../lib/GridNumber'

export default class TicTacToe {
  static toggle = (action, value) => {
    Dispatcher.dispatch({
      type: action,
      data: value
    })
  }

  static evalHitAction = (team, pts) => {
    let currentScore = [...team.ticTacToe]

    if (pts === 0) {
      return this.toggle(Actions.EDIT_TEAM_TIC_TAC_TOE_SCORE, { teamId: team.id, score: currentScore, throwCount: 1 })
    }

    const index = pts - 1

    if (currentScore[index] !== 0) { return }

    currentScore[index] = 1

    this.toggle(Actions.EDIT_TEAM_TIC_TAC_TOE_SCORE, { teamId: team.id, score: currentScore, throwCount: 1 })
  }

  static getTeamDisplayScore = (teamData) => {
    return teamData ? `- ${teamData.throwCount} throws` : ''
  }

  static editTempScoreForTeam = (index, state) => {
    if (state[0].ticTacToe[index] === 0 && state[1].ticTacToe[index] === 0) {
      let newScore = [...state[0].ticTacToe]
      newScore[index] = 1

      this.toggle(Actions.TEMP_EDIT_TEAM_TIC_TAC_TOE_SCORE, { teamId: 1, score: newScore, throwCount: 1 })
    } else if (state[0].ticTacToe[index] === 1) {
      let newScore1 = [...state[0].ticTacToe]
      newScore1[index] = 0
      let newScore2 = [...state[1].ticTacToe]
      newScore2[index] = 1

      this.toggle(Actions.TEMP_EDIT_TEAM_TIC_TAC_TOE_SCORE, { teamId: 1, score: newScore1, throwCount: 0 })
      this.toggle(Actions.TEMP_EDIT_TEAM_TIC_TAC_TOE_SCORE, { teamId: 2, score: newScore2, throwCount: 1 })
    } else if (state[1].ticTacToe[index] === 1) {
      let newScore = [...state[1].ticTacToe]
      newScore[index] = 0

      this.toggle(Actions.TEMP_EDIT_TEAM_TIC_TAC_TOE_SCORE, { teamId: 2, score: newScore, throwCount: 0 })
    }
  }

  static updateTeamTicTacToeScoreAfterEdit = () => {
    this.toggle(Actions.UPDATE_TEAM_TIC_TAC_TOE_SCORE_AFTER_EDIT)
  }

  static editTempThrowsForTeam = (teamId, throws) => {
    const hitData = { teamId: teamId, throws: throws }

    this.toggle(Actions.TEMP_EDIT_TEAM_THROWS, hitData)
  }

  static setGridNumbers = () => {
    return gridNumbers[Math.floor(Math.random() * gridNumbers.length)]
  }
}
