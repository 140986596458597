import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use((request) => {
    request.headers.common['Authorization'] = process.env.REACT_APP_API_TOKEN
    return request
})

instance.interceptors.response.use((response) => {
    return response;
},
(err) => {
    if (err && err.response && err.response.status === 304) {
        return err.response
    }

    // alert(err.message);
    return Promise.reject(err);
});

export default instance;
