import React from 'react'
import Game from '../lib/Game'
import Display from '../lib/Display'
import EmailScores from './EmailScores'
import TicTacToe from '../lib/TicTacToe'
import ModalThrowCount from './ModalThrowCount'
import RemainingTimeClock from './RemainingTimeClock'

export default class ScoreboardTicTacToe extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      teamId: null,
      showThrowModal: null
    }
  }

  scoreStyle = (index, state) => {
    if (state.length !== 0 && state[0] && state[1]) {
      if (state[0].ticTacToe[index] === 1) {
        return "cross-score"
      } else if (state[1].ticTacToe[index] === 1) {
        return "circle-score"
      }
    }

    return ""
  }

  editTempScore = (index, state) => {
    TicTacToe.editTempScoreForTeam(index, state)
  }

  setTeamToEditThrows = teamId => {
    this.setState({ teamId: teamId })
    this.toggleThrowModal(true)
  }

  toggleThrowModal = (val) => {
    this.setState({ showThrowModal: val })
  }

  editThrows = (throws) => {
    if (throws >= 0) {
      TicTacToe.editTempThrowsForTeam(this.state.teamId, throws)
    }

    this.toggleThrowModal(false)
  }

  updateScoresAndClose = () => {
    Game.setGameStateToModified()
    TicTacToe.updateTeamTicTacToeScoreAfterEdit()
    Display.toggleLeagueScoreboard(false)

    setTimeout( () => {
      Game.sendGameState(this.props.app, this.props.game)
    }, 1500)
  }

  gridText = index => {
    const gridNumbers = this.props.game.gridNumbers

    if (gridNumbers[index] !== 'BB') {
      return gridNumbers[index]
    } else {
      return <span style={{ fontSize: "0.5em", textAlign: "center" }}>Blue Ball</span>
    }
  }

  render() {
    let stateType

    if (!this.props.game.modifyGameState) {
      stateType = this.props.game.gameState
    } else {
      stateType = this.props.game.modifyGameState
    }

    return (
      <>
        <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
          <div id='score-grid' className='tic-tac-toe-scoreboard'>
            <table className='throw-count-table' style={{ width: "400px" }}>
              <tbody>
                <tr>
                  <td colSpan="2">Throws</td>
                </tr>
                <tr>
                  <td>{stateType.length > 0 && stateType[0] ? stateType[0].name : ""}</td>
                  <td>{stateType.length > 0 && stateType[1] ? stateType[1].name : ""}</td>
                </tr>
                <tr>
                  <td onClick={() => this.setTeamToEditThrows(1)}>{stateType.length > 0 && stateType[0] ? stateType[0].throwCount : ""}</td>
                  <td onClick={() => this.setTeamToEditThrows(2)}>{stateType.length > 0 && stateType[1] ? stateType[1].throwCount : ""}</td>
                </tr>
              </tbody>
            </table>
            <div className="game-score-board">
              <div className={`box-score ${this.scoreStyle(0, stateType)}`} onClick={() => this.editTempScore(0, stateType)}>{this.gridText(0)}</div>
              <div className={`box-score ${this.scoreStyle(1, stateType)}`} onClick={() => this.editTempScore(1, stateType)}>{this.gridText(1)}</div>
              <div className={`box-score ${this.scoreStyle(2, stateType)}`} onClick={() => this.editTempScore(2, stateType)}>{this.gridText(2)}</div>
              <div className={`box-score ${this.scoreStyle(3, stateType)}`} onClick={() => this.editTempScore(3, stateType)}>{this.gridText(3)}</div>
              <div className={`box-score ${this.scoreStyle(4, stateType)}`} onClick={() => this.editTempScore(4, stateType)}>{this.gridText(4)}</div>
              <div className={`box-score ${this.scoreStyle(5, stateType)}`} onClick={() => this.editTempScore(5, stateType)}>{this.gridText(5)}</div>
              <div className={`box-score ${this.scoreStyle(6, stateType)}`} onClick={() => this.editTempScore(6, stateType)}>{this.gridText(6)}</div>
              <div className={`box-score ${this.scoreStyle(7, stateType)}`} onClick={() => this.editTempScore(7, stateType)}>{this.gridText(7)}</div>
              <div className={`box-score ${this.scoreStyle(8, stateType)}`} onClick={() => this.editTempScore(8, stateType)}>{this.gridText(8)}</div>
            </div>
          </div>
          { this.props.game.gameEnded ?
            <EmailScores gameId={this.props.game.gameId} game={this.props.game.game} />
          :
            <div className="actions" style={{position: "absolute", left: this.props.app.shownTimeWarning ? "12%" : "24%", bottom: "0"}}>
              <RemainingTimeClock {...this.props} />
              <div className="btn btn-outline btn-cancel" onClick={() => Display.toggleLeagueScoreboard(false)}>Cancel</div>
              <div className="btn btn-gold btn-save" onClick={this.updateScoresAndClose}>Save &amp; Return</div>
            </div>
          }
        </div>

        <ModalThrowCount
          showModal={this.state.showThrowModal}
          toggleModal={this.toggleThrowModal}
          editThrows={this.editThrows} />
      </>
    )
  }
}
