import React from 'react'
import Display from '../lib/Display'

export default class ModalEditScore extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      score: '',
      isScoreInvalid: false,
    }
  }

  closeModal = () => {
    Display.toggleModalKeypad21(false)
    this.setState({ score: '', isScoreInvalid: false })
  }

  updateAndClear = () => {
    if (this.state.score === '' ) { return }

    if (this.state.score > 51) {
      this.setState({ isScoreInvalid: true })
      return
    }

    this.props.editPlayerScore(parseInt(this.state.score))
    Display.toggleModalKeypad21(false)
    this.setState({ score: '', isScoreInvalid: false })
  }

  updateScore = (e) => this.setState({ score: e.target.value })

  render() {
    return (
      <div className={`modal modal-score-21 underlay ${this.props.showModal ? "active" : ""}`}>
        <div className="modal-body">
          <div className="field-container team-name-1" style={{ padding: "0px" }}>
            <div className="input-group">
              <input
                type="number"
                onChange={this.updateScore}
                value={this.state.score}
                autoComplete="off" />
            </div>
          </div>
          {this.state.isScoreInvalid && <span>should not be more than 51</span>}
          <div className="actions" style={{marginLeft: ".1rem"}}>
            <div className="btn btn-outline btn-block btn-close" style={{ display: "inline-block", marginRight: ".1rem" }} onClick={this.closeModal}>Cancel</div>
            <div className="btn btn-gold btn-block btn-close" style={{ display: "inline-block" }} onClick={this.updateAndClear}>Submit</div>
          </div>
        </div>
      </div>
    )
  }
}
