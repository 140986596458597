import React from 'react';
import Lane from '../lib/Lane';
import Game from '../lib/Game';
import Display from '../lib/Display';

export default class GameSetupScoring extends React.Component {
    setScoringStyle = (style) => {
        Game.setScoringStyle(style);
        Display.toggleGameSetupScoring(false);
        if (style === 'Individual' || style === 'Lane Total') {
            Game.copyTeamSetup();
            Display.toggleGameSetup(false);
            Display.togglePlayerSetup(true);
        }
    }

    goBack = () => {
        Lane.setCurrentGame(null);
        Game.setGame(null);
        Game.setScoringStyle(null);
        Display.toggleGameSetupScoring(false);
        Display.toggleGameSetup(false);
        Display.toggleBrowseGames(true);
    }

    showInstructions = () => {
        Lane.setInstructionGame(this.props.game.game)
        Display.toggleInstructions(true);
    }

    chooseTicTacToe = () => {
        this.setScoringStyle('Team Total')
        Game.setTeamNames(['Team X', 'Team O']);
        Game.copyTeamSetup();
        Display.toggleGameSetup(false);
        Display.toggleGameSetupTeamNames(false);
        Display.togglePlayerSetup(true);
    }

    render() {
        return (
            <div
                id="scoring"
                className={`button-stack button-stack-single ${this.props.display.showGameSetupScoring ? "active" : "inactive"} ${Game.hasModifiers(this.props.game.game) ? "has-modifiers" : "no-modifiers"}`}
                style={{ width: this.props.game.game === 'Tic Tac Toe' ? "" : "800px" }}
                >
                <div style={{ width: "800px", height: "200px", padding: this.props.game.game === 'Tic Tac Toe' ? "" : "20px" }}>
                    {this.props.game.game === 'Tic Tac Toe' ?
                    <>
                    <div className="btn btn-gold" onClick={this.chooseTicTacToe}>Start Game</div>
                    <div className="btn btn-gold" onClick={this.showInstructions}>How to Play</div>
                    </>
                    :
                    <>
                    <div style={{ float: "left", width: "50%" }}>
                        <div className="btn btn-gold" onClick={() => this.setScoringStyle('Individual')}>Individual</div>
                        <div className="btn btn-gold" onClick={() => this.setScoringStyle('Lane Total')}>Lane Total</div>
                    </div>
                    <div style={{ float: "left", width: "50%" }}>
                        <div className="btn btn-gold" onClick={() => this.setScoringStyle('Team Total')}>Team Total</div>
                        <div className="btn btn-gold" onClick={() => this.showInstructions()}>How to Play</div>
                    </div>
                    </>
                    }
                </div>
                <div
                    className="btn btn-outline"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    onClick={this.goBack}
                >Choose Another Game
                </div>
            </div>
        )
    }
}
