import React from 'react';
import Lane from '../lib/Lane';
import Game from '../lib/Game';
import Display from '../lib/Display';

export default class BrowseGames extends React.Component {
    evalGameClick = (game) => {
        // If the group hasn't been shown the training yet, this button
        // should open the game information.
        // If they have been shown training, the group is selecting their
        // game.
        if (!this.props.app.completedTraining) {
            Lane.setInstructionGame(game)
            Display.toggleInstructions(true);
        } else {
            Lane.setCurrentGame(game);
            Game.setGame(game);
            Display.toggleGameSetupScoring(true);
            Display.toggleBrowseGames(false);
            Display.toggleGameSetup(true);
        }
    }

    render() {
        return (
            <div id="browse-games" className={`button-stack button-stack-double ${this.props.display.showBrowseGames ? "active" : ""}`}>
                <div className="btn btn-gold anim-1" rel="anim" onClick={() => this.evalGameClick('League Style')}>League Style</div>
                <div className="btn btn-gold anim-2" rel="anim" onClick={() => this.evalGameClick('Bucket List')}>Bucket List</div>
                <div className="btn btn-gold anim-3" rel="anim" onClick={() => this.evalGameClick('First to 21/51')}>First to 21/51</div>
                <div className="btn btn-gold anim-4" rel="anim" onClick={() => this.evalGameClick('Magic Number')}>Magic Number</div>
                <div className="btn btn-gold anim-5" rel="anim" onClick={() => this.evalGameClick('Tic Tac Toe')}>Tic Tac Toe</div>
                <div className="btn btn-gold anim-6" rel="anim" onClick={() => this.evalGameClick('Around the World')}>Around the World</div>
                {this.props.app.completedTraining ?
                <div className="btn btn-gold anim-7" rel="anim" onClick={() => this.evalGameClick('Open Throwing')}>Open Throwing</div>
                :
                <>
                <div className="btn btn-gold anim-8" rel="anim" onClick={() => this.evalGameClick('Open Throwing')}>Open Throwing</div>
                {!this.props.app.groupInLane && <div className="btn btn-outline anim-9" rel="anim" onClick={() => Display.toggleBrowseGames(false)}>Go Back</div>}
                </>
                }
            </div>
        )
    }
}