import Request from './Request';
import Endpoints from './Endpoints';
import Dispatcher from '../dispatchers/Dispatcher';
import Actions from './Actions';

/**
 * Helper class around reservations
 */
export default class Reservation {

    static getLaneName = (response) => {
        if (response && response.data && response.data.data) {
            if (response.data.data.resource != null) {
                Dispatcher.dispatch({
                    type: Actions.SET_LANE_NAME,
                    data: response.data.data.resource.name,
                })
            }
        }
    }

    static processImages = (response) => {
        if (response && response.data && response.data.data) {
            if (response.data.data.appLogo !== null) {
                Dispatcher.dispatch({
                    type: Actions.SET_APP_LOGO,
                    data: response.data.data.appLogo
                });
            } else {
                Dispatcher.dispatch({
                    type: Actions.RESET_APP_LOGO,
                    data: response.data.data.appLogo
                });
            }

            if (response.data.data.targetImage !== null && response.data.data.targetImage !== "") {
                Dispatcher.dispatch({
                    type: Actions.SET_TARGET_IMAGE,
                    data: response.data.data.targetImage
                });
            } else {
                Dispatcher.dispatch({
                    type: Actions.RESET_TARGET_IMAGE,
                    data: response.data.data.targetImage
                });
            }

            Dispatcher.dispatch({
                type: Actions.SET_INITIAL_FETCH,
                data: true
            });
        }
    }

    /**
     * Load a reservation and dispatch its data with LOAD_RESERVATION
     */
    static load = (locationId, laneNumber, callback) => {
        // do nothing if location & lane number aren't loaded into app...
        if (!locationId || !laneNumber) {
            return
        }

        Request.get(Endpoints.getReservation(locationId, laneNumber)).then((response) => {
            Dispatcher.dispatch({
                type: Actions.LOAD_RESERVATION,
                data: response.data.data
            });

            // we should no longer need to process images since it is now its own endpoint
            // this.processImages(response);

            if (callback) {
                callback();
            }
        }).catch(err => {
            // this.processImages(err.response);
            this.getLaneName(err.response);
        });
    }

    /**
    * Load location assets
    */
    static loadAssets = (locationId) => {
        if (!locationId) {
            return
        }

        Request.get(Endpoints.getLocationAssets(locationId)).then((response) => {
            this.processImages(response);
        })
    }
}
