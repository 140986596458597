import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
import FirstTo21 from '../lib/FirstTo21';
import EmailScores from './EmailScores';
import Modal21Keypad from './Modal21Keypad';
import ModalEditScore from './ModalEditScore';
import ModalThrowCount from './ModalThrowCount';
import SingleScoreTable from './SingleScoreTable';
import RemainingTimeClock from './RemainingTimeClock'

export default class Scoreboard21 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            playerId: null,
            teamId: null,
            score: null,
            throwCount: null,
            editingScore: null,
            showThrowModal: null,
            emails: null
        }
    }

    toggleThrowModal = (val) => {
        this.setState({ showThrowModal: val });
    }

    renderTeams = (gameState) => {
        if (gameState.length > 0) {
            return gameState.map((team, index) => {
                return (
                    <div className='blob scoreboard-21' key={index}>
                        <table key={index}>
                            <tbody>
                                <tr className='score-row'>
                                    <th className='score-header'>{this.props.game.scoringStyle === 'Lane Total' ? `Lane ${this.props.app.laneNumber}` : team.name}</th>
                                </tr>
                                <tr>
                                    <td className='score' onClick={() => this.setTeamToEdit(team.id)}>{team.singleScore}</td>
                                </tr>
                                <tr>
                                    <td onClick={() => this.setTeamToEditThrows(team.id)}>{team.throwCount} throws</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            });
        }
    }

    renderPlayerScores = (gameState) => {
        if (gameState.length > 0) {
            return gameState.map((team, index) => {
                return team.players.map((player, index) => {
                    return (
                        <SingleScoreTable
                            setPlayerToEdit={this.setPlayerToEdit}
                            setPlayerToEditThrows={this.setPlayerToEditThrows}
                            playerData = {player}
                            name={player.name}
                            id={player.id}
                            index={index}
                            key={index} />
                    );
                })
            })
        };
    }

    setPlayerToEdit = (playerId) => {
        this.setState({playerId: playerId});
        Display.toggleModalKeypad21(true);
    }

    setPlayerToEditThrows = (playerId) => {
        this.setState({playerId: playerId});
        this.toggleThrowModal(true);
    }

    setTeamToEdit = (teamId) => {
        this.setState({teamId: teamId});
        Display.toggleModalKeypad21(true);
    }

    setTeamToEditThrows = (teamId) => {
        this.setState({teamId: teamId});
        this.toggleThrowModal(true);
    }

    editScore = (score) => {
        // If the score is >= 0, we know it's a real score.
        if (score >= 0) {
            this.setState({score: score}, () => {
                if (this.props.game.scoringStyle === 'Individual') {
                    FirstTo21.editTempScoreForPlayer(this.state.playerId, this.state.score);
                } else {
                    FirstTo21.editTempScoreForTeam(this.state.teamId, this.state.score);
                }
            });
        }
        Display.toggleModalKeypad21(false);
    }

    editThrows = (throws) => {
        // If the score is >= 0, we know it's a real score.
        if (throws >= 0) {
            if (this.props.game.scoringStyle === 'Individual') {
                FirstTo21.editTempThrowsForPlayer(this.state.playerId, throws);
            } else {
                FirstTo21.editTempThrowsForTeam(this.state.teamId, throws);
            }
        }
        this.toggleThrowModal(false);
    }

    updateScoresAndClose = () => {
        Game.setGameStateToModified();
        if (this.props.game.scoringStyle === 'Individual') {
            Game.updatePlayerScoreAfterEdit();
        } else {
            Game.updateTeamScoreAfterEdit();
        }
        // Toggle large keyboard instead
        Display.toggleLeagueScoreboard(false);

        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
        }, 1500);
    }

    render() {
        let stateType;
        if (!this.props.game.modifyGameState) {
            stateType = this.props.game.gameState;
        } else {
            stateType = this.props.game.modifyGameState
        }

        return (
            <>
                <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
                    <div id='score-grid' className="score-grid" style={{marginTop: ".5rem", textAlign: "center"}}>
                        { this.props.game.scoringStyle !== 'Individual' &&
                            this.renderTeams(stateType)
                        }
                        { this.props.game.scoringStyle === 'Individual' &&
                            this.renderPlayerScores(stateType)
                        }

                    </div>
                    { this.props.game.gameEnded
                        ? 
                            <EmailScores gameId={this.props.game.gameId} game={this.props.game.game} />
                        :
                            <div className="actions" style={{position: "absolute", left: this.props.app.shownTimeWarning ? "12%" : "24%", bottom: "0"}}>
                                <RemainingTimeClock {...this.props} />
                                <div className="btn btn-outline btn-cancel" onClick={() => Display.toggleLeagueScoreboard(false)}>Cancel</div>
                                <div className="btn btn-gold btn-save" onClick={this.updateScoresAndClose}>Save &amp; Return</div>
                            </div>
                    }
                </div>
                {this.props.game.maxScore === 21 ?
                <Modal21Keypad
                    showModalKeypad21={this.props.display.showModalKeypad21}
                    editPlayerScore={this.editScore} />
                :
                <ModalEditScore
                    showModal={this.props.display.showModalKeypad21}
                    editPlayerScore={this.editScore} />
                }

                <ModalThrowCount
                    showModal={this.state.showThrowModal}
                    toggleModal={this.toggleThrowModal}
                    editThrows={this.editThrows} />
            </>
        )
    }
}