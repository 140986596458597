import React from 'react'
import Game from '../lib/Game'
import Display from '../lib/Display'
import ModalScoreMessage from './ModalScoreMessage'
import TicTacToe from '../lib/TicTacToe'

export default class GridTarget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      logScore: null,
      gridNumber: null,
      popClass: null
    }
  }

  componentDidMount() {
    Game.setGridNumbers(TicTacToe.setGridNumbers())
  }

  cancelLogScore = () => {
    this.setState({ logScore: false })
  }

  addPopClass = gridNumber => {
    if (gridNumber === 0) {
      this.setState({ popClass: 'action-miss' })
    } else {
      this.setState({ popClass: `square-${gridNumber}` })
    }
  }

  pickMessage = gridNumber => {
    let message

    if (gridNumber > 0) {
      message = this.props.app.hitMessages[Math.floor(Math.random() * this.props.app.hitMessages.length)]
    } else {
      message = this.props.app.missMessages[Math.floor(Math.random() * this.props.app.missMessages.length)]
    }

    Game.setModalScoreMessage(message)
  }

  calculateHit = gridNumber => {
    if (gridNumber !== 0 && (this.props.game.gameState[0].ticTacToe[gridNumber - 1] !== 0 || this.props.game.gameState[1].ticTacToe[gridNumber - 1] !== 0)) { return }

    this.addPopClass(gridNumber)
    this.setState({ logScore: true, gridNumber: gridNumber })
    this.pickMessage(gridNumber)
    Display.toggleShowHitModal(true)

    setTimeout( () => {
      if (this.state.logScore) {
        Game.updateEventData(this.props.game.gameState, this.props.game.currentThrower, this.props.game.currentTeam, gridNumber);
        TicTacToe.evalHitAction(this.props.game.currentTeam, gridNumber)
      }

      setTimeout( () => {
        Game.sendGameState(this.props.app, this.props.game)
      }, 1500)

      Display.toggleShowHitModal(false)
      this.setState({ popClass: null })
    }, 1500)
  }

  scoreStyle = index => {
    const gameState = this.props.game.gameState

    if (gameState.length !== 0 && gameState[0] && gameState[1]) {
      if (gameState[0].ticTacToe[index] === 1) {
        return "cross"
      } else if (gameState[1].ticTacToe[index] === 1) {
        return "circle"
      }
    }

    return ""
  }

  isPop = gridNumber => {
    return this.state.popClass === `square-${gridNumber}` ? "pop" : ""
  }

  gridText = index => {
    const gridNumbers = this.props.game.gridNumbers

    if (gridNumbers[index] !== 'BB') {
      return gridNumbers[index]
    } else {
      return <span className='blue-ball'></span>
    }
  }

  handleShuffle = () => {
    Game.setGridNumbers(TicTacToe.setGridNumbers())
  }

  handleCompleteGame = () => {
    Display.toggleEndGameModal(true);
  }

  render() {
    return (
      <>
      <div id='grid-target'>
        <div className="game-board">
          <div className={`box ${this.scoreStyle(0)} action-square ${this.isPop(1)}`} onClick={() => this.calculateHit(1)}>{this.gridText(0)}</div>
          <div className={`box ${this.scoreStyle(1)} action-square ${this.isPop(2)}`} onClick={() => this.calculateHit(2)}>{this.gridText(1)}</div>
          <div className={`box ${this.scoreStyle(2)} action-square ${this.isPop(3)}`} onClick={() => this.calculateHit(3)}>{this.gridText(2)}</div>
          <div className={`box ${this.scoreStyle(3)} action-square ${this.isPop(4)}`} onClick={() => this.calculateHit(4)}>{this.gridText(3)}</div>
          <div className={`box ${this.scoreStyle(4)} action-square ${this.isPop(5)}`} onClick={() => this.calculateHit(5)}>{this.gridText(4)}</div>
          <div className={`box ${this.scoreStyle(5)} action-square ${this.isPop(6)}`} onClick={() => this.calculateHit(6)}>{this.gridText(5)}</div>
          <div className={`box ${this.scoreStyle(6)} action-square ${this.isPop(7)}`} onClick={() => this.calculateHit(7)}>{this.gridText(6)}</div>
          <div className={`box ${this.scoreStyle(7)} action-square ${this.isPop(8)}`} onClick={() => this.calculateHit(8)}>{this.gridText(7)}</div>
          <div className={`box ${this.scoreStyle(8)} action-square ${this.isPop(9)}`} onClick={() => this.calculateHit(9)}>{this.gridText(8)}</div>
        </div>
        <div className="ring-miss" onClick={() => this.calculateHit(0)}></div>
        <div className="point-labels">
          <div className="point-label miss miss-right" style={{ left: "calc(50% + 5rem)" }} onClick={() => this.calculateHit(0)}>Miss/Drop</div>
          <div className="point-label miss miss-bottom" onClick={() => this.calculateHit(0)}>Miss/Drop</div>

          {this.props.game.gameState.length === 2 && this.props.game.gameState[0].throwCount === 0 && this.props.game.gameState[1].throwCount === 0 ?
          <div className="point-label shuffle-points" onClick={this.handleShuffle}><i className="fa fa-random"></i> Shuffle Points</div>
          :
          <div className="point-label shuffle-points" onClick={this.handleCompleteGame}><i className="fa fa-times"></i> Complete Game</div>
          }
        </div>
      </div>

      <ModalScoreMessage
        gameType={this.props.game.game}
        message={this.props.game.modalScoreMessage}
        game={this.props.game ? true : false}
        currentThrower={this.props.game && this.props.game.currentThrower ? this.props.game.currentThrower : null}
        throwCount={this.props.game && this.props.game.throwCount ? this.props.game.throwCount : null}
        showHitModal={this.props.display.showHitModal}
        cancelLogScore={this.cancelLogScore}
        logScore={this.state.logScore}
        points={this.state.gridNumber} />
      </>
    )
  }
}