import React from 'react'
import Game from '../lib/Game'

export default class OpenThrowingBlueBalls extends React.Component {
  render() {
    return (
      <div
        className={`button-select-21-style ${!this.props.game.scoringStyle && this.props.game.game === 'Open Throwing' ? "active" : "inactive"}`}
        style={{ marginTop: "80px" }}
      >
        <div
          className={`btn ${this.props.game.openThrowingBlueBallScore === 8 ? "btn-gold" : "btn-outline"}`}
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          onClick={() => Game.setOpenThrowingBlueBallScore(8)}
          >Blue Balls = 8pt
        </div>
        <div
          className={`btn ${this.props.game.openThrowingBlueBallScore === 10 ? "btn-gold" : "btn-outline"}`}
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          onClick={() => Game.setOpenThrowingBlueBallScore(10)}
        >Blue Balls = 10 pt
        </div>
      </div>
    )
  }
}
