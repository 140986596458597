export const gridNumbers = [
  [1, 2, 3, 4, 6, 4, 3, 2, 1],
  [4, 3, 2, 6, 1, 3, 2, 6, 4],
  [3, 2, 1, 6, 4, 6, 1, 2, 3],
  [2, 3, 2, 1, 6, 1, 3, 4, 3],
  [4, 3, 1, 2, 6, 2, 1, 3, 4],
  [6, 4, 3, 1, 2, 1, 3, 4, 6],
  [3, 2, 3, 4, 'BB', 4,	2, 3, 2],
  [1, 'BB',	4, 6,	3, 2,	2, 1,	6],
  [6, 3, 1, 2, 'BB', 3, 4, 2, 6],
  [2, 4, 'BB', 3, 6, 1, 4, 2, 3],
  [2, 1, 3, 6, 4, 6, 3, 1, 2],
  [1, 2, 1, 3, 'BB', 3, 2, 1,	2],
  [2, 1, 6, 3, 6, 4, 6, 1, 2],
  [6, 4, 'BB', 1,	2, 1,	3, 4,	3],
  [3, 4, 3, 6, 1, 6, 4, 2, 1],
  [1, 'BB',	3, 6,	4, 2,	3, 1,	2],
  ['BB', 1,	3, 6,	2, 4,	4, 1,	'BB'],
  [4, 3, 4, 3, 4, 3, 4, 3, 4],
  [3, 2, 3, 2, 3, 2, 3, 2, 3],
  [1, 4, 1, 4, 1, 4, 1, 4, 1],
  [3, 1, 3, 1, 3, 1, 3, 1, 3],
  [6, 4, 6, 4, 6, 4, 6, 4, 6],
  [1, 'BB',	1, 'BB', 1, 'BB',	1, 'BB', 1],
  [1, 2, 2, 1, 1, 2, 2, 2, 1],
  [2, 4, 2, 4, 2, 4, 2, 4, 2],
  [6, 3, 6, 3, 6, 3, 6, 3, 6],
  [1, 6, 1, 6, 1, 6, 1, 6, 1],
  [1, 1, 1, 'BB',	4, 2,	3, 'BB', 6],
]
