import Actions from '../lib/Actions'
import Display from '../lib/Display'
import Dispatcher from '../dispatchers/Dispatcher'

export default class AroundTheWorld {

  static toggle = (action, value) => {
    Dispatcher.dispatch({
      type: action,
      data: value
    })
  }

  static updatePlayerAroundTheWorldScoreAfterEdit = () => {
    this.toggle(Actions.UPDATE_PLAYER_AROUND_THE_WORLD_SCORE_AFTER_EDIT)
  }

  static updateTeamAroundTheWorldScoreAfterEdit = () => {
    this.toggle(Actions.UPDATE_TEAM_AROUND_THE_WORLD_SCORE_AFTER_EDIT)
  }

  // Based on the points value coming in, we can determine which
  // index to access to modify the appropriate score. 1-4 matches the rings,
  // 6 is a bullseye (index 4), and 'blue' is the blue ball (index 5)
  static calculateAroundTheWorldIndex = (pts) => {
    if (pts === 'blue') {
      return 9
    } else if (pts === 6) {
      return 4
    } else {
      return pts - 1
    }
  }

  static indexOfFirstZero = arr => {
    for (let i = 0; i < arr.length; i++)
      if (arr[i] === 0)
        return i
    return -1
  }

  static evalHitAction = (player, team, scoringStyle, blueBalls, pts) => {
    const index = this.calculateAroundTheWorldIndex(pts)

    if (scoringStyle === 'Individual') {
      const currentRing = this.indexOfFirstZero(player.aroundTheWorld)
      let aroundTheWorldScore = [...player.aroundTheWorld]

      if (currentRing >= 0 && currentRing <= 4) {
        if (index === currentRing) {
          aroundTheWorldScore[currentRing] = 1
        } else {
          Display.toggleRingClosedModal(`Your current ring is ${currentRing === 4 ? "Bullseye" : currentRing + 1}`)
        }
      } else if (currentRing >= 5 && currentRing <= 8) {
        if (index === 8 - currentRing) {
          aroundTheWorldScore[currentRing] = 1
        } else {
          Display.toggleRingClosedModal(`Your current ring is ${9 - currentRing}`)
        }
      } else if (blueBalls && currentRing === 9) {
        if (index === 9) {
          aroundTheWorldScore[currentRing] = 1
        } else {
          Display.toggleRingClosedModal(`Your current ring is Blue ball`)
        }
      } else {
        Display.toggleRingClosedModal('Rings are closed!')
      }

      const hitData = { playerId: player.id, teamId: team, score: aroundTheWorldScore }
      this.toggle(Actions.EDIT_PLAYER_AROUND_THE_WORLD_SCORE, hitData)
    } else {
      let currentRing = this.indexOfFirstZero(team.aroundTheWorld)
      let aroundTheWorldScore = [...team.aroundTheWorld]

      if (currentRing >= 0 && currentRing <= 4) {
        if (index === currentRing) {
          aroundTheWorldScore[currentRing] = 1
        } else {
          Display.toggleRingClosedModal(`Your current ring is ${currentRing === 4 ? "Bullseye" : currentRing + 1}`)
        }
      } else if (currentRing >= 5 && currentRing <= 8) {
        if (index === 8 - currentRing) {
          aroundTheWorldScore[currentRing] = 1
        } else {
          Display.toggleRingClosedModal(`Your current ring is ${9 - currentRing}`)
        }
      } else if (blueBalls && currentRing === 9) {
        if (index === 9) {
          aroundTheWorldScore[currentRing] = 1
        } else {
          Display.toggleRingClosedModal(`Your current ring is Blue ball`)
        }
      } else {
        Display.toggleRingClosedModal('Rings are closed!')
      }

      const hitData = { teamId: team.id, score: aroundTheWorldScore }
      this.toggle(Actions.EDIT_TEAM_AROUND_THE_WORLD_SCORE, hitData)
    }

    return false
  }

  static getAroundTheWorldScore = (index, data) => {
    if (data.aroundTheWorld[index] === 1) {
      return 'X'
    } else {
      return ''
    }
  }

  static editTempScoreForPlayer = (aroundTheWorldIndex, playerData) => {
    let aroundTheWorldScore = [...playerData.aroundTheWorld]

    if (aroundTheWorldScore[aroundTheWorldIndex] === 1) {
      aroundTheWorldScore[aroundTheWorldIndex] = 0
    } else {
      aroundTheWorldScore[aroundTheWorldIndex] = 1
    }

    let hitData = { playerId: playerData.id, score: aroundTheWorldScore }
    this.toggle(Actions.TEMP_EDIT_PLAYER_AROUND_THE_WORLD_SCORE, hitData)
  }

  static editTempScoreForTeam = (aroundTheWorldIndex, teamData) => {
    let aroundTheWorldScore = [...teamData.aroundTheWorld]

    if (aroundTheWorldScore[aroundTheWorldIndex] === 1) {
      aroundTheWorldScore[aroundTheWorldIndex] = 0
    } else {
      aroundTheWorldScore[aroundTheWorldIndex] = 1
    }

    let hitData = { teamId: teamData.id, score: aroundTheWorldScore }
    this.toggle(Actions.TEMP_EDIT_TEAM_AROUND_THE_WORLD_SCORE, hitData)
  }

  static getPlayerDisplayScore = (playerData, gameData) => {
    const currentRing = this.indexOfFirstZero(playerData.aroundTheWorld)
    let ring

    if (currentRing === -1 || (!gameData.aroundTheWorldBlueBalls && currentRing === 9)) {
      ring = "Complete"
    } else if (currentRing <= 3) {
      ring = "Ring " + (currentRing + 1)
    } else if (currentRing === 4){
      ring = "Bullseye"
    } else if (currentRing <= 8) {
      ring = "Ring " + (9 - currentRing)
    } else if (gameData.aroundTheWorldBlueBalls) {
      ring = "Blue ball"
    }

    return ring + " (" + playerData.throwCount + " throws)"
  }

  static getTeamDisplayScore = (teamData, gameData) => {
    return teamData ? ` - ${this.getPlayerDisplayScore(teamData, gameData)}` : ''
  }

  static playerGameStatus = (playerData, includeBlueBalls) => {
    const currentRing = this.indexOfFirstZero(playerData.aroundTheWorld)

    if (includeBlueBalls && currentRing === -1) {
      return true
    } else if (currentRing === 9) {
      return true
    }

    return false
  }

  static editTempThrowsForPlayer = (playerId, throws) => {
    const hitData = { playerId: playerId, throws: throws }

    this.toggle(Actions.TEMP_EDIT_PLAYER_THROWS, hitData)
  }

  static editTempThrowsForTeam = (teamId, throws) => {
    const hitData = { teamId: teamId, throws: throws }

    this.toggle(Actions.TEMP_EDIT_TEAM_THROWS, hitData)
  }
}