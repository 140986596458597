import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
import Request from '../lib/Request';
import Endpoints from '../lib/Endpoints';

export default class ModalEndGame extends React.Component {

    async createGameId() {
        const validatePromise = await Request.put(Endpoints.endGame(this.props.reservationNumber, this.props.laneNumber), {
            json_block: { 
                "game": this.props.game.game, "readyToPlay": this.props.game.readyToPlay, "gameState": this.props.game.gameState,
                "scoringStyle": this.props.game.scoringStyle, "currentThrower":  this.props.game.currentThrower,
                "currentTeam": this.props.game.currentTeam, "bucketListBlueBalls": this.props.game.bucketListBlueBalls, 
                "bustOrStay": this.props.game.bustOrStay, "magicNumberBlueBalls": this.props.game.magicNumberBlueBalls,
                "shownTimeWarning": this.props.app.shownTimeWarning, "agreedTimeWarning": this.props.app.agreedTimeWarning,
                "reservationTimeout": this.props.app.reservationTimeout, "reservationExtended": this.props.app.reservationExtended,
                "throwData": { "gameState": this.props.game.eventGameState, "currentThrower": this.props.game.eventCurrentThrower, "currentTeam": this.props.game.eventCurrentTeam, "points": this.props.game.eventPoints, "timestamp": this.props.game.eventTimestamp }
            }
        });
        return validatePromise;
    }

    // TODO: allow group to continue if ther was an error ending the game on the server
    endGame = () => {
        this.createGameId().then(response => {
            // console.log(response);
            if (response.status === 200 && response.data.data.gameResultId) {
                Game.setGameId(response.data.data.gameResultId);
                // Store the current team setup here in case players were changed around during gameplay
                Game.storeTeamSetup();
                Game.endGame();
            } else {
                alert('There was a problem creating a game ID. Please try again');
                // Game.storeTeamSetup();
                // Game.endGame();
            }
        });
    }

    emailScores = () => {
        Game.setGameStateToModify(this.props.gameState);
        Display.toggleEndGameModal(false);
        Display.toggleLeagueScoreboard(true);
    }

    playAgain = () => {
        Game.setCurrentThrower(this.props.firstPlayer);
        Game.playAgain();
        Display.toggleEndGameModal(false);
        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
        }, 1500);
    }

    chooseNewGame = () => {
        Game.chooseNewGame();
        Display.toggleEndGameModal(false);
        Display.toggleLeagueScoreboard(false)
        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
        }, 1500);
    }

    gamerino = () => {
        if (this.props.gameEnded) {
            return (
                <div className={`modal modal-end underlay ${this.props.showEndGameModal ? "active" : "inactive"}`}>
                    <div className="modal-body">
                        <h2>Options</h2>
                        <div className="btn btn-gold" style={{width: "100%", marginBottom: '20px', marginTop: '20px'}} onClick={this.emailScores}>Email Scores</div>
                        <br></br>
                        <div className="btn btn-gold" style={{width: "100%", marginBottom: '20px'}} onClick={this.playAgain}>Play Again</div>
                        <br></br>
                        <div className="btn btn-gold" style={{width: "100%", marginBottom: '20px'}} onClick={this.chooseNewGame}>Choose New Game</div>
                        <br></br>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`modal modal-end underlay ${this.props.showEndGameModal ? "active" : "inactive"}`}>
                    <div className="modal-body">
                        <h2>End Game?</h2>
                        <div className="btn btn-gold" style={{width: "100%", marginBottom: '20px', marginTop: '20px'}} onClick={this.endGame}>End Current Game</div>
                        <div className="modal-actions" style={{paddingBottom: '20px'}}>
                            <div className="btn btn-outline btn-close" onClick={() => Display.toggleEndGameModal(false)}>Close</div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        let whichModal = this.gamerino();
        return (
            <div>{whichModal}</div>
        )
    }
}