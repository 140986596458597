import React from 'react';

export default class ModalScoreMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldUpdate: true,
            points: null,
        };
    }

    // Wew lad
    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps) {
            return false;
        } else if (nextProps.logScore !== this.props.logScore) {
            return true;
        } else if (nextProps.showHitModal && this.state.shouldUpdate) {
            return true;
        } else if (nextProps.showHitModal === false && this.state.shouldUpdate) {
            return false;
        } else if (nextProps.showHitModal === false) {
            return true;
        } else if (!this.state.shouldUpdate) {
            return false;
        } else if (nextProps.showHitModal) {
            return  true;
        } else {
            return false;
        }
    }

    pickMessage = (showModal) => {
        if (showModal) {
            if (this.props.logScore === false) {
                return this.props.message;
            } else {
                this.setState({ shouldUpdate: false });
                return this.props.message;
            }
        } else {
            this.setState({ shouldUpdate: true });
            return this.props.message;
        }
    }

    evalPoints = (showModal) => {
        if (showModal) {
            if (this.props.logScore === false) {
                return this.state.points;
            } else {
                if (this.props.points === 'blue') {
                    if (this.props.game && this.props.currentThrower && (this.props.currentThrower.throwCount === 4 || this.props.currentThrower.throwCount === 9)) {
                        this.setState({points: 8, shouldUpdate: false});
                        return 8;
                    } else {
                        this.setState({points: 1, shouldUpdate: false});
                        return 1;
                    }
                } else {
                    this.setState({points: this.props.points, shouldUpdate: false});
                    return this.props.points;
                }
            }
        } else {
            this.setState({shouldUpdate: true});
            return this.state.points;
        }
    }

    render() {
        return (
            <div className={`modal modal-score underlay ${this.props.showHitModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h1>{this.pickMessage(this.props.showHitModal)}</h1>
                    {this.props.gameType !== "Around the World" && this.props.gameType !== "Tic Tac Toe" &&
                        <h2>{this.evalPoints(this.props.showHitModal)}</h2>
                    }
                    <div className="btn btn-clear btn-block" onClick={() => this.props.cancelLogScore()}>{`${this.props.logScore ? "Undo" : "Not logged"}`}</div>
                </div>
            </div>
        )
    }
}