import React from 'react'

export default class RemainingTimeClock extends React.Component {
  evalTimeRemaining = () => {
    let timeRemaining = Math.floor((this.props.app.endTime - Date.now())/1000/60) //off by two minutes for some reason - assumed rounding error
    let evalTime

    if (timeRemaining > 0) {
      evalTime = timeRemaining
    } else if (this.props.app.reservationExtended) {
      evalTime = 0
    } else {
      evalTime = '< 1'
    }

    return `${evalTime} minutes`
  }

  render() {
    return (
      <>
        { this.props.app.shownTimeWarning &&
          <div id="remaining-time" style={{ display: 'inline-block', marginRight: '10px'}}>
            <h2>{this.evalTimeRemaining()}</h2>
          </div>
        }
      </>
    )
  }
}
