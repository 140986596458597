import React from 'react'
import Game from '../lib/Game'

export default class AroundTheWorldBlueBalls extends React.Component {
  render() {
    return (
      <div
        className={`button-select-21-style ${!this.props.scoringStyle && this.props.game === 'Around the World' ? "active" : "inactive"}`}
        style={{ marginTop: "80px" }}
      >
        <div
          className={`btn ${this.props.selection !== true ? "btn-gold" : "btn-outline"}`}
          onClick={() => Game.setAroundTheWorldBlueBalls(false)}
        >No Blue Balls
        </div>
        <div
          className={`btn ${this.props.selection === true ? "btn-gold" : "btn-outline"}`}
          onClick={() => Game.setAroundTheWorldBlueBalls(true)}
        >Blue Balls
        </div>
      </div>
    )
  }
}
