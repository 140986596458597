import React from 'react'
import Game from '../lib/Game'
import Display from '../lib/Display'
import OpenThrowing from '../lib/OpenThrowing'
import EmailScores from './EmailScores'
import ModalEditScore from './ModalEditScore'
import ModalThrowCount from './ModalThrowCount'
import SingleScoreTable from './SingleScoreTable'

export default class ScoreboardOpenThrowing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playerId: null,
      teamId: null,
      score: null,
      throwCount: null,
      editingScore: null,
      showThrowModal: null,
      emails: null
    }
  }

  toggleThrowModal = (val) => {
    this.setState({ showThrowModal: val });
  }

  renderTeams = (gameState) => {
    if (gameState.length > 0) {
      return gameState.map((team, index) => {
        return (
          <div className='blob scoreboard-21' key={index}>
            <table key={index}>
              <tbody>
                <tr className='score-row'>
                  <th className='score-header'>{this.props.game.scoringStyle === 'Lane Total' ? `Lane ${this.props.app.laneNumber}` : team.name}</th>
                </tr>
                <tr>
                  <td className='score' onClick={() => this.setTeamToEdit(team.id)}>{team.singleScore}</td>
                </tr>
                <tr>
                  <td onClick={() => this.setTeamToEditThrows(team.id)}>{team.throwCount} throws</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })
    }
  }

  renderPlayerScores = (gameState) => {
    if (gameState.length > 0) {
      return gameState.map((team) => {
        return team.players.map((player, index) => {
          return (
            <SingleScoreTable
              setPlayerToEdit={this.setPlayerToEdit}
              setPlayerToEditThrows={this.setPlayerToEditThrows}
              playerData = {player}
              name={player.name}
              id={player.id}
              index={index}
              key={index} />
          )
        })
      })
    }
  }

  setPlayerToEdit = (playerId) => {
    this.setState({playerId: playerId});
    Display.toggleModalKeypad21(true);
  }

  setPlayerToEditThrows = (playerId) => {
    this.setState({playerId: playerId});
    this.toggleThrowModal(true);
  }

  setTeamToEdit = (teamId) => {
    this.setState({teamId: teamId});
    Display.toggleModalKeypad21(true);
  }

  setTeamToEditThrows = (teamId) => {
    this.setState({teamId: teamId});
    this.toggleThrowModal(true);
  }

  editScore = (score) => {
    if (score >= 0) {
      this.setState({ score: score }, () => {
        if (this.props.game.scoringStyle === 'Individual') {
          OpenThrowing.editTempScoreForPlayer(this.state.playerId, this.state.score);
        } else {
          OpenThrowing.editTempScoreForTeam(this.state.teamId, this.state.score);
        }
      })
    }

    Display.toggleModalKeypad21(false)
  }

  editThrows = (throws) => {
    if (throws >= 0) {
      if (this.props.game.scoringStyle === 'Individual') {
        OpenThrowing.editTempThrowsForPlayer(this.state.playerId, throws);
      } else {
        OpenThrowing.editTempThrowsForTeam(this.state.teamId, throws);
      }
    }

    this.toggleThrowModal(false);
  }

  updateScoresAndClose = () => {
    Game.setGameStateToModified()

    if (this.props.game.scoringStyle === 'Individual') {
      Game.updatePlayerScoreAfterEdit();
    } else {
      Game.updateTeamScoreAfterEdit();
    }

    Display.toggleLeagueScoreboard(false);

    setTimeout( () => {
      Game.sendGameState(this.props.app, this.props.game);
    }, 1500);
  }

  render() {
    let stateType

    if (!this.props.game.modifyGameState) {
      stateType = this.props.game.gameState
    } else {
      stateType = this.props.game.modifyGameState
    }

    return (
      <>
        <div className={`scoreboard scoreboard-league ${this.props.display.showLeagueScoreboard ? "active" : "inactive"}`}>
          <div id='score-grid' className="score-grid" style={{ marginTop: ".5rem", textAlign: "center" }}>
            { this.props.game.scoringStyle !== 'Individual' &&
              this.renderTeams(stateType)
            }
            { this.props.game.scoringStyle === 'Individual' &&
              this.renderPlayerScores(stateType)
            }
        </div>

        { this.props.game.gameEnded ?
          <EmailScores gameId={this.props.game.gameId} game={this.props.game.game} />
          :
          <div className="actions" style={{ position: "absolute", left: "24%", bottom: "0" }}>
            <div className="btn btn-outline btn-cancel" onClick={() => Display.toggleLeagueScoreboard(false)}>Cancel</div>
            <div className="btn btn-gold btn-save" onClick={this.updateScoresAndClose}>Save &amp; Return</div>
          </div>
        }
        </div>

        <ModalEditScore
          showModal={this.props.display.showModalKeypad21}
          editPlayerScore={this.editScore} />

        <ModalThrowCount
            showModal={this.state.showThrowModal}
            toggleModal={this.toggleThrowModal}
            editThrows={this.editThrows} />
      </>
    )
  }
}
