import React from 'react';

export default class SingleScoreTable extends React.Component {

    render() {
        return (
            <div className={`blob scoreboard-21 ${this.props.index >= 4 ? 'second-row' : ''}`}>
                <table>
                    <tbody>
                        <tr className='score-row'>
                            <th className='score-header'>{this.props.name}</th>
                        </tr>
                        <tr>
                            <td className='score' onClick={() => this.props.setPlayerToEdit(this.props.playerData.id)}>{this.props.playerData.singleScore}</td>
                        </tr>
                        <tr>
                            <td onClick={() => this.props.setPlayerToEditThrows(this.props.playerData.id)}>{this.props.playerData.throwCount} throws</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}
