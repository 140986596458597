import Actions from '../lib/Actions';
import Dispatcher from '../dispatchers/Dispatcher';
import LeagueStyle from '../lib/LeagueStyle';
import FirstTo21 from '../lib/FirstTo21';
import BucketList from '../lib/BucketList';
import MagicNumber from '../lib/MagicNumber';
import AroundTheWorld from '../lib/AroundTheWorld';
import TicTacToe from '../lib/TicTacToe';
import OpenThrowing from '../lib/OpenThrowing';

import Endpoints from '../lib/Endpoints';
import Request from '../lib/Request';

export default class Game {
    static toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    static setGame = (game) => {
        this.toggle(Actions.SET_GAME, game);
    }

    static setScoringStyle = (value) => {
        this.toggle(Actions.SET_SCORING_STYLE, value);
    }

    static toggleCompletedTraining = (value) => {
        this.toggle(Actions.COMPLETED_TRAINING, value);
    }

    static setCurrentGame = (value) => {
        this.toggle(Actions.SET_CURRENT_GAME, value);
    }

    static setTeamNames = (teams) => {
        this.toggle(Actions.SET_TEAM_NAMES, teams);
    }

    static toggleReadyToPlay = (value) => {
        this.toggle(Actions.READY_TO_PLAY, value);
    }

    static setCurrentThrower = (throwerId) => {
        this.toggle(Actions.SET_CURRENT_THROWER, throwerId);
    }

    static sendGameState = (appProps, gameProps) => {
        Request.post(Endpoints.sendGameState(appProps.reservationNumber, appProps.laneNumber), {
            json_block: { 
                            "game": gameProps.game, "readyToPlay": gameProps.readyToPlay, "gameState": gameProps.gameState,
                            "scoringStyle": gameProps.scoringStyle, "currentThrower":  gameProps.currentThrower,
                            "currentTeam": gameProps.currentTeam, "bucketListBlueBalls": gameProps.bucketListBlueBalls, 
                            "bustOrStay": gameProps.bustOrStay, "maxScore": gameProps.maxScore, "magicNumberBlueBalls": gameProps.magicNumberBlueBalls, "aroundTheWorldBlueBalls": gameProps.aroundTheWorldBlueBalls,
                            "openThrowingBlueBallScore": gameProps.openThrowingBlueBallScore, "gridNumbers": gameProps.gridNumbers,
                            "shownTimeWarning": appProps.shownTimeWarning, "agreedTimeWarning": appProps.agreedTimeWarning,
                            "modalScoreMessage": gameProps.modalScoreMessage,
                            "reservationTimeout": appProps.reservationTimeout, "reservationExtended": appProps.reservationExtended,
                            "throwData": { "gameState": gameProps.eventGameState, "currentThrower": gameProps.eventCurrentThrower, "currentTeam": gameProps.eventCurrentTeam, "points": gameProps.eventPoints, "timestamp": gameProps.eventTimestamp }
                        }
        });
        // if (appProps.groupInLane) {
        //     setTimeout( () => {
        //         Request.post(Endpoints.sendGameState(appProps.reservationNumber, appProps.laneNumber), {
        //             json_block: { 
        //                             "game": gameProps.game, "readyToPlay": gameProps.readyToPlay, "gameState": gameProps.gameState,
        //                             "scoringStyle": gameProps.scoringStyle, "currentThrower":  gameProps.currentThrower,
        //                             "currentTeam": gameProps.currentTeam, "bucketListBlueBalls": gameProps.bucketListBlueBalls, 
        //                             "bustOrStay": gameProps.bustOrStay, "magicNumberBlueBalls": gameProps.magicNumberBlueBalls,
        //                             "shownTimeWarning": appProps.shownTimeWarning, "agreedTimeWarning": appProps.agreedTimeWarning,
        //                             "reservationTimeout": appProps.reservationTimeout, "reservationExtended": appProps.reservationExtended,
        //                             "throwData": { "gameState": gameProps.eventGameState, "currentThrower": gameProps.eventCurrentThrower, "currentTeam": gameProps.eventCurrentTeam, "points": gameProps.eventPoints, "timestamp": gameProps.eventTimestamp }
        //                         }
        //         });
        //     }, 1500);
        // }
    }

    // Handle cases where no one is assigned to the first team
    static setInitialThrower = (teamData) => {
        if (teamData[0].players.length > 0) {
            this.toggle(Actions.SET_CURRENT_THROWER, teamData[0].players[0]);
        } else {
            this.toggle(Actions.SET_CURRENT_THROWER, teamData[1].players[0]);
        }
    }

    // Handle cases where no one is assigned to the first team
    static getInitialThrower = (teamData) => {
        if (teamData[0] && teamData[0].players && teamData[0].players.length > 0) {
            return teamData[0].players[0];
        } else if (teamData[1] && teamData[0].players && teamData[1].players.length > 0){
            return teamData[1].players[0];
        } else {
            return null;
        }
    }

    static setCurrentTeam = (team) => {
        this.toggle(Actions.SET_CURRENT_TEAM, team);
    }

    // Handle cases where no one is assigned to the first team
    static getInitialTeamId = (teamData) => {
        if (teamData[0].players.length > 0) {
            return teamData[0].players[0].teamId;
        } else {
            return teamData[1].players[0].teamId;
        }
    }

    /**
     * Game-specific logic for how to handle creating hit records for different players.
     * Some games only care about a single score, not individual records of hits.
     * Check which one we actually care about
     * 
     * THIS IS NOW ONLY FOR LEAGUE STYLE GAMES. OTHER GAMES HAVE THEIR OWN CLASS
     */
    static createHitForPlayer = (playerData, pts, gameState, scoringStyle) => {
        if (playerData.throwCount < 10) {
            let hitRecord = { id: playerData.id, points: pts };
            this.toggle(Actions.CREATE_HIT_FOR_PLAYER, hitRecord);

            // Update the gamestate copy here to what it will be after the dispatch action.
            // Need to do this to evaluate the end of game calculations
            gameState = gameState.map((team) => {
                return {
                    ...team, 
                    players: team.players.map((player) => {
                        if (player.id === playerData.id) {
                            return {
                                ...player,
                                throws: player.throws.concat({ throwNumber: player.throws.length, points: pts, circleHit: pts }),
                                throwCount: player.throws.length + 1
                            }
                        } else {
                            return player;
                        }
                    })
                }
            })

            // Check if we are assigned the 10th throw for a player then check for end game stats
            if (playerData.throwCount === 10) {
                console.log('evaling game over');
                let gameOver = true;
                let winnerName = [];
                let winnerScore = 0;

                if (scoringStyle === 'Individual') {
                    // alert('individ');
                    gameState.forEach((team) => {
                        team.players.forEach((player) => {
                            if (player.throws.length === 10) {
                                // console.log(player.name + " has 10 throws");
                                let playerScore = LeagueStyle.getTotalScoreForPlayer(player);
                                // console.log(player.name + " has " + playerScore);
                                if (playerScore === winnerScore) {
                                    // console.log ('tied score');
                                    winnerName.push(player.name);
                                } else if (playerScore > winnerScore) {
                                    // console.log('new high score');
                                    winnerScore = playerScore;
                                    winnerName = [player.name];
                                }
                            } else {
                                // console.log(player.name + " doesn't have 10 throws");
                                gameOver = false;
                            }
                        })
                    });            
                } else if (scoringStyle === 'Team Total') {
                    let allThrows = true;
                    // alert('team');

                    gameState.forEach((team) => {
                        team.players.forEach((player) => {
                            if (player.throws.length !== 10) {
                                allThrows = false;
                                gameOver = false;
                            }
                        });
                    });
                    
                    if (allThrows) {
                        gameState.forEach((team) => {
                            let teamScore = LeagueStyle.getTotalScoreForTeam(team);
                            if (teamScore === winnerScore) {
                                // console.log ('tied score');
                                winnerName.push(team.name);
                            } else if (teamScore > winnerScore) {
                                // console.log('new high score');
                                winnerName = [team.name];
                                winnerScore = teamScore;
                            } else {
                                // console.log('not on board')
                            }
                        });  
                    }
                } else {
                    let allThrows = true;
                    // alert('team');

                    gameState.forEach((team) => {
                        team.players.forEach((player) => {
                            if (player.throws.length !== 10) {
                                allThrows = false;
                                gameOver = false;
                            }
                        });
                    });
                    
                    if (allThrows) {
                        gameState.forEach((team) => {
                            winnerScore = LeagueStyle.getTotalScoreForTeam(team);
                        })
                    }
                }

                if (gameOver) {
                    // console.log(winnerName);
                    // console.log(winnerScore);
                    let winnerData = { winnerName: winnerName, winnerScore: winnerScore, game: 'League Style', scoringStyle: scoringStyle };
                    this.toggle(Actions.WINNER_MESSAGE, winnerData);
                }
            }
        }

    }

    static createTempHitForPlayer = (playerId, pts) => {
        let hitRecord = { id: playerId, points: pts };
        this.toggle(Actions.CREATE_TEMP_HIT_FOR_PLAYER, hitRecord); 
    }

    static editThrowForPlayer = (playerId, score, throwNo) => {
        let throwData = { playerId: playerId, score: score, throwNo: throwNo };
        this.toggle(Actions.EDIT_PLAYER_THROW, throwData);
    }

    static editTempThrowForPlayer = (playerId, score, throwNo) => {
        let throwData = { playerId: playerId, score: score, throwNo: throwNo };
        this.toggle(Actions.EDIT_TEMP_PLAYER_THROW, throwData);
    }

    static tempRemovePlayerScore = (playerId) => {
        let throwData = { playerId: playerId };
        this.toggle(Actions.REMOVE_TEMP_PLAYER_THROW, throwData);
    }

    static resetGameState = () => {
        this.toggle(Actions.RESET_GAME_STATE)
    }

    static setGameStateToModify = (gameState) => {
        this.toggle(Actions.SET_MODIFY_GAME_STATE); 
    }

    static setGameStateToModified = (gameState) => {
        this.toggle(Actions.SET_GAME_STATE_TO_MODIFIED);
    }

    static assignPlayerToTeam = (player) => {
        this.toggle(Actions.ASSIGN_PLAYER_TO_TEAM, player);
    }

    static removePlayerFromTeam = (player) => {
        this.toggle(Actions.REMOVE_PLAYER_FROM_TEAM, player);
    }

    static setActiveTeam = (id) => {
        this.toggle(Actions.SET_ACTIVE_TEAM, id);
    }

    static sortPlayer = (oldIndex, newIndex) => {
        this.toggle(Actions.SORT_PLAYER, { oldIndex: oldIndex, newIndex: newIndex })
    }

    static setBustOrStay = (bs) => {
        this.toggle(Actions.BUST_OR_STAY, bs)
    }

    static setMaxScore = maxScore => {
        this.toggle(Actions.MAX_SCORE, maxScore);
    }

    static setGridNumbers = gridNumbers => {
        this.toggle(Actions.GRID_NUMBERS, gridNumbers);
    }

    static setModalScoreMessage = modalScoreMessage => {
        this.toggle(Actions.MODAL_SCORE_MESSAGE, modalScoreMessage);
    }

    static setBucketListBlueBalls = (val) => {
        this.toggle(Actions.BUCKET_LIST_BLUE_BALLS, val);
    }

    static setMagicNumberBlueBalls = (val) => {
        this.toggle(Actions.MAGIC_NUMBER_BLUE_BALLS, val);
    }

    static setAroundTheWorldBlueBalls = (val) => {
        this.toggle(Actions.AROUND_THE_WORLD_BLUE_BALLS, val);
    }

    static setOpenThrowingBlueBallScore = (val) => {
        this.toggle(Actions.OPEN_THROWING_BLUE_BALL_SCORE, val);
    }

    static updatePlayerThrowCountAfterEdit = () => {
        this.toggle(Actions.UPDATE_PLAYER_THROW_COUNT_AFTER_EDIT);
    }

    static updatePlayerScoreAfterEdit = () => {
        this.toggle(Actions.UPDATE_PLAYER_SCORE_AFTER_EDIT);
    }

    static updateTeamScoreAfterEdit = () => {
        this.toggle(Actions.UPDATE_TEAM_SCORE_AFTER_EDIT);
    }

    static hasModifiers = (game) => {
        return (game === 'First to 21/51' || game === 'Bucket List' || game === 'Magic Number' || game === 'Around the World' || game === 'Open Throwing');
    }

    static endGame = () => {
        this.toggle(Actions.END_GAME);
    }

    static setGameId = (id) => {
        this.toggle(Actions.SET_GAME_ID, id);
    }

    static playAgain = () => {
        this.toggle(Actions.PLAY_AGAIN);
    }

    static chooseNewGame = () => {
        this.toggle(Actions.CHOOSE_NEW_GAME);
    }

    static storeTeamSetup = () => {
        this.toggle(Actions.STORE_TEAM_SETUP);
    }

    static copyTeamSetup = () => {
        this.toggle(Actions.COPY_TEAM_SETUP);
    }

    static showPlayerScores = (gameData) => {
        // If the game is not bucket list and the game isn't first to 21 with either the team total or lane total styles,
        // we want to show the player scores
        if ( !( gameData.game === 'Bucket List' && (gameData.scoringStyle === 'Team Total' || gameData.scoringStyle === 'Lane Total') ) && !( gameData.game === 'First to 21' && (gameData.scoringStyle === 'Team Total' || gameData.scoringStyle === 'Lane Total') ) && !( gameData.game === 'Around the World' && (gameData.scoringStyle === 'Team Total' || gameData.scoringStyle === 'Lane Total') ) ) {
            return true;
        } else {
            return false;
        }
    }

    static getPlayersClass = (gameData) => {
        if (this.showPlayerScores(gameData)) {
            return 'reduced-padding';
        } else {
            return '';
        }
    }

    static getPlayerScore = (playerData, gameName, gameData) => {
        switch(gameName) {
            case "League Style":
                return LeagueStyle.getPlayerDisplayScore(playerData);
            case "First to 21/51":
                // We do not  want to show individual player scores when playing
                // this game as a team or a lane as a whole as they are not tracked
                // per player in that instance
                return gameData.scoringStyle === 'Individual' ? FirstTo21.getPlayerDisplayScore(playerData, gameData.maxScore) : '';
            case "Bucket List":
                return gameData.scoringStyle === 'Individual' ? BucketList.getPlayerDisplayScore(playerData) : '';
            case "Magic Number":
                return MagicNumber.getPlayerDisplayScore(playerData, gameData.magicNumberBlueBalls);
            case "Around the World":
                return gameData.scoringStyle === 'Individual' ? AroundTheWorld.getPlayerDisplayScore(playerData, gameData) : '';
            case "Open Throwing":
                return gameData.scoringStyle === 'Individual' ? OpenThrowing.getPlayerDisplayScore(playerData) : '';
            default:
                return '';
        }
    }

    static getTeamScore = (teamData, gameName, gameData) => {
        switch(gameName) {
            case "League Style":
                return LeagueStyle.getTeamDisplayScore(teamData);
            case "First to 21/51":
                return FirstTo21.getTeamDisplayScore(teamData, gameData.maxScore);
            case "Bucket List":
                return BucketList.getTeamDisplayScore(teamData);
            case "Magic Number":
                return MagicNumber.getTeamDisplayScore(teamData, gameData.magicNumberBlueBalls);
            case "Around the World":
                return AroundTheWorld.getTeamDisplayScore(teamData, gameData);
            case "Tic Tac Toe":
                return TicTacToe.getTeamDisplayScore(teamData, gameData);
            case "Open Throwing":
                return OpenThrowing.getTeamDisplayScore(teamData, gameData);
            default:
                return '';
        }
    }

    static playerGameStatus = (playerData, gameData) => {
        switch(gameData.game) {
            case "League Style":
                if (LeagueStyle.playerGameStatus(playerData)) {
                    this.showPlayerGameStatusModal(playerData.name + " has completed this game.");
                }
                break;
            case "First to 21/51":
                if (FirstTo21.playerGameStatus(playerData, gameData.scoringStyle, gameData.gameState, gameData.maxScore)) {
                    this.showPlayerGameStatusModal(playerData.name + ` is already at ${gameData.maxScore}!`);
                }
                break;
            case "Bucket List":
                if (BucketList.playerGameStatus(playerData, gameData.bucketListBlueBalls)) {
                    this.showPlayerGameStatusModal(playerData.name + " has completed this game.");
                }
                break;
            case "Magic Number":
                if (MagicNumber.playerGameStatus(playerData, gameData.magicNumberBlueBalls)) {
                    this.showPlayerGameStatusModal(playerData.name + " has completed this game.");
                }
                break;
            case "Around the World":
                if (AroundTheWorld.playerGameStatus(playerData, gameData.aroundTheWorldBlueBalls)) {
                    this.showPlayerGameStatusModal(playerData.name + " has completed this game.");
                }
                break;
            default:
                return false;
        }
    }

    static showPlayerGameStatusModal = (message) => {
        this.toggle(Actions.SET_PLAYER_STATUS_MODAL, { showModal: true, message: message });
        setTimeout( () => {
            this.toggle(Actions.SET_PLAYER_STATUS_MODAL, { showModal: false, message: message });
        }, 2000);
    }

    static updateEventData = (gameState, currentThrower, currentTeam, pts) => {
        let eventData = { gameState: gameState, currentThrower: currentThrower, currentTeam: currentTeam, points: pts }
        this.toggle(Actions.UPDATE_EVENT_DATA, eventData);
    }
}