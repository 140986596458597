import React from 'react';
import Lane from '../lib/Lane';
import Display from '../lib/Display';
import Game from '../lib/Game';
import Request from '../lib/Request';
import Endpoints from '../lib/Endpoints';


export default class ModalTimeWarning extends React.Component {
    closeAndMarkAgreed = () => {
        Lane.agreedTimeWarning(true);
        Display.toggleTimeWarningModal(false);
        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
            Request.put(Endpoints.markReservationAsWarned(this.props.reservationNumber))
        }, 1500);
    }
    render() {
        return (
            <div className={`modal modal-end underlay ${this.props.showModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h2 style={{marginBottom: '25px'}}>Just a quick heads up, your reservation ends in 15 minutes!</h2>

                    {/* <div className="btn btn-gold" style={{width: "100%", marginBottom: '20px', marginTop: '20px'}} onClick={() => Display.toggleTimeWarningModal(false)}>I Understand</div> */}

                    <div className="modal-actions" style={{paddingBottom: '20px'}}>
                        <div className="btn btn-outline btn-close" onClick={this.closeAndMarkAgreed}>Got it!</div>
                    </div>
                </div>
            </div>
        )
    }
}