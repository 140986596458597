import React from 'react';
import Game from '../lib/Game';
import Display from '../lib/Display';
// import GameMenu from './GameMenu';
import LeaguePlayer from './LeaguePlayer';
import ModalTargetMenu from './ModalTargetMenu';

export default class PlayersLeagueTeams extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenu: null
        };
    }

    toggleMenuModal = (shouldShow) => {
        this.setState({ showMenu: shouldShow });
    }

    toggleScoreBoard = () => {
        Game.setGameStateToModify(this.props.gameState);
        Display.toggleLeagueScoreboard(true);
    }

    renderPlayers = (players, teamScore, bucketList, aroundTheWorld, ticTacToe) => {
        return players.map((player, index) => {
            return (
                <LeaguePlayer
                    currentThrower={this.props.game.currentThrower}
                    player={player}
                    singleScore={player.singleScore}
                    name={player.name}
                    id={player.id} 
                    // TODO: Update with different game type scores
                    team={ {id: player.teamId, singleScore: teamScore, bucketList: bucketList, aroundTheWorld: aroundTheWorld, ticTacToe: ticTacToe} }
                    teamScore={teamScore}
                    key={index} 
                    gameName={this.props.game.game} 
                    game={this.props.game} 
                    setCompletedMessage={this.setCompletedMessage} 
                    sendPlayerChange={this.sendPlayerChange}/>
            );
        });
    }

    sendPlayerChange = () => {
        setTimeout( () => {
            Game.sendGameState(this.props.app, this.props.game);
        }, 1500);
    }

    getStyleClass = (gameName) => {
        if (gameName) {
            if (gameName === "First to 21/51") {
                return "first-to-21"
            } else {
                return gameName.replace(/\s+/g, '-').toLowerCase()
            }
        }

        return ""
    }


    render() {
        return (
            <>
                <div id="game-players" className="multi-team">
                    <div id="new-brand">
                        <img src={this.props.app.appLogo} alt="logo" />
                    </div>
                    <div className={`game-name ${this.getStyleClass(this.props.game.game)}`}>{this.props.game.game}</div>
                    <div className="players">
                        {/* <div className="selector"></div> */}
                        <div className="team team-1">
                            { this.props.game.scoringStyle === 'Team Total' &&
                                <h3 className="bold">
                                    {this.props.game.game === 'Tic Tac Toe' ? `${this.props.game.teams[0]}'s` : this.props.game.teams[0]}
                                    {(this.props.game.game === 'Tic Tac Toe' || this.props.game.game === 'First to 21/51') && <br/>}
                                    {Game.getTeamScore(this.props.game.gameState[0], this.props.game.game, this.props.game)}
                                </h3>
                            }
                            { this.props.game.scoringStyle === 'Lane Total' && <h3 className="bold">Lane {Game.getTeamScore(this.props.game.gameState[0], this.props.game.game, this.props.game)}</h3> }
                            <ul>
                                {this.props.game.gameState[0] && this.renderPlayers(this.props.game.gameState[0].players, this.props.game.gameState[0].singleScore, this.props.game.gameState[0].bucketList, this.props.game.gameState[0].aroundTheWorld, this.props.game.gameState[0].ticTacToe)}
                            </ul>
                        </div>
                        { this.props.game.scoringStyle === 'Team Total' && this.props.game.teams[1] &&
                            <div className="team team-2">
                                <h3 className="bold">
                                    {this.props.game.game === 'Tic Tac Toe' ? `${this.props.game.teams[1]}'s` : this.props.game.teams[1]}
                                    {(this.props.game.game === 'Tic Tac Toe' || this.props.game.game === 'First to 21/51') && <br/>}
                                    {Game.getTeamScore(this.props.game.gameState[1], this.props.game.game, this.props.game)}</h3>
                                <ul>
                                    {this.props.game.gameState[1] && this.renderPlayers(this.props.game.gameState[1].players, this.props.game.gameState[1].singleScore, this.props.game.gameState[1].bucketList, this.props.game.gameState[1].aroundTheWorld, this.props.game.gameState[1].ticTacToe)}
                                </ul>
                            </div>
                        }
                    </div>
                    
                    <div className="btn btn-gold btn-game-menu" onClick={this.toggleScoreBoard}>View Scores</div>
                    <div className="btn btn-gold btn-game-menu" onClick={() => this.toggleMenuModal(true)}>Menu</div>
                    {/* padding: .1rem;
                    width: 70%;
                    margin-left: .22rem;
                    margin-bottom: .1rem; */}
                    
                </div>

                <ModalTargetMenu
                    showMenu={this.state.showMenu}
                    toggleMenuModal={this.toggleMenuModal}
                    gameState={this.props.gameState} />
            </>
        )
    }
}
