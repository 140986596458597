import React from 'react';
import Game from '../lib/Game';

export default class MaxScoreOption extends React.Component {
  render() {
    return (
      <div
        className={`button-select-21-style ${!this.props.scoringStyle && this.props.game === 'First to 21/51' ? "active" : "inactive"}`}
        style={{ marginTop: "90px" }}>
        <div className={`btn ${this.props.selection === 51 ? "btn-outline" : "btn-gold"}`} onClick={() => Game.setMaxScore(21)}>21</div>
        <div className={`btn ${this.props.selection === 51 ? "btn-gold" : "btn-outline"}`} onClick={() => Game.setMaxScore(51)}>51</div>
      </div>
    )
  }
}
