import React from 'react';
// import GameMenu from './GameMenu';
import PlayersLeagueTeams from './PlayersLeagueTeams'

// TODO: Structure has changed - cleanup to all use the same one

export default class GamePlayers extends React.Component {
    evalLeagueScoring = () => {
        switch (this.props.game.scoringStyle) {
            case 'Individual':
                // return <PlayersLeagueIndividual />;
                return <PlayersLeagueTeams {...this.props} />;
            case 'Team Total':
                return <PlayersLeagueTeams {...this.props} />;
            case 'Lane Total':
                // return <PlayersLeagueLane />;
                return <PlayersLeagueTeams {...this.props} />;
            default:
                return <PlayersLeagueTeams {...this.props} />;
        }
    }

    render() {
        let playersComponent;
        switch (this.props.game.game) {
            case 'League Style':
                playersComponent = <PlayersLeagueTeams {...this.props} />;
                break;
            case 'First to 21/51':
                playersComponent = <PlayersLeagueTeams {...this.props} />;
                break;
            case 'Bucket List':
                playersComponent = <PlayersLeagueTeams {...this.props} />;
                break;
            // TODO: Maybe delete this
            case 'Magic Number':
                playersComponent = <PlayersLeagueTeams {...this.props} />;
                break;
            case 'Around the World':
                playersComponent = <PlayersLeagueTeams {...this.props} />;
                break;
            case 'Open Throwing':
                playersComponent = <PlayersLeagueTeams {...this.props} />;
                break;
            case 'Tic Tac Toe':
                playersComponent = <PlayersLeagueTeams {...this.props} />;
                break;
            default:
                break;
        }

        return (
            <div>
                {playersComponent}
            </div>
        )
    }
}