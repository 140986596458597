import React from 'react'
import AroundTheWorld from '../lib/AroundTheWorld'

export default class AroundTheWorldPlayerScores extends React.Component {
  render() {
    return (
      <tr className="player">
        <th>{this.props.name}</th>
        <td className='td-bl' onClick={() => this.props.setPlayerToEditThrows(this.props.playerData.id)}>{this.props.playerData.throwCount}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(0, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(0, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(1, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(1, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(2, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(2, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(3, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(3, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(4, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(4, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(5, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(5, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(6, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(6, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(7, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(7, this.props.playerData)}</td>
        <td className='td-bl' onClick={() => this.props.editPlayerScore(8, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(8, this.props.playerData)}</td>
        {this.props.blueBalls &&
          <td className='td-bl' onClick={() => this.props.editPlayerScore(9, this.props.playerData)}>{AroundTheWorld.getAroundTheWorldScore(9, this.props.playerData)}</td>
        }
      </tr>
    )
  }
}
