import React from 'react';
import Game from '../lib/Game';

export default class BustOrStay extends React.Component {

    render() {
        return (
            <div className={`button-select-21-style ${!this.props.scoringStyle && this.props.game.game === 'First to 21/51' ? "active" : "inactive"}`}>
                <div className={`btn ${this.props.selection === 'Stay' ? "btn-outline" : "btn-gold"}`} onClick={() => Game.setBustOrStay('Bust')}>
                    Bust to {this.props.game.maxScore === 21 ? 13 : 43}
                </div>
                <div className={`btn ${this.props.selection === 'Stay' ? "btn-gold" : "btn-outline"}`} onClick={() => Game.setBustOrStay('Stay')}>Bust = Stay</div>
            </div>
        )
    }
}