import React from 'react';

export default class ModalBustReset extends React.Component {
    render() {
        return (
            <div className={`modal modal-score underlay ${this.props.showModal ? "active" : "inactive"}`}>
                <div className="modal-body">
                    <h1>Bust!</h1>
                    <h2 style={{paddingBottom: "20px"}}>Reset to {this.props.maxScore === 21 ? 13 : 43}</h2>
                </div>
            </div>
        )
    }
}